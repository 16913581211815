
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/request'
import { ApplyStatus, ContentList, DatePracticeInfo, Plan, PlanStatus, SurveyList, Task } from '@/models/plan'
import dayjs from 'dayjs'
import { Dialog, Notify, Toast } from 'vant'
import { AuthStore } from '@/store/modules/auth'
import NaviBar from '@/components/NaviBar.vue'
import { Practice } from '@/models/practice'
import PracticeCard from '@/components/PracticeCard.vue'
import PlanTeacherDetailPracticeCard from '@/components/PlanTeacherDetailPracticeCard.vue'

@Options({
  components: {
    NaviBar, PracticeCard, PlanTeacherDetailPracticeCard
  }
})
export default class PlanDetail extends Vue {
  isApply = false
  planId = ''
  applyStatus = ApplyStatus
  status = PlanStatus
  auth = AuthStore
  isShowDialog = false
  isTeacherPlan = false
  isInfoShow = false
  currentPractice!: DatePracticeInfo
  cover = ''
  name = ''
  phone = ''
  plan: Plan = {
    cover: '',
    createTime: '',
    endTime: '',
    id: 0,
    introduction: '',
    isAllowRetrain: false,
    name: '',
    planContentList: [],
    planSurveyList: [],
    publishStatus: '',
    startTime: ''
  }

  planPractices: Practice[] = []
  currentPlan: Plan = {
    cover: '',
    createTime: '',
    endTime: '',
    id: 0,
    introduction: '',
    isAllowRetrain: false,
    name: '',
    planContentList: [],
    planSurveyList: [],
    publishStatus: '',
    startTime: ''
  }

  get isTeacher () {
    return AuthStore.isTeacher
  }

  getDateMMDD (date: string) {
    const dateStr = dayjs(date).format('M月D日')
    if (dateStr !== 'Invalid Date') {
      return dateStr
    }
    return ''
  }

  mounted () {
    this.isTeacherPlan = this.$route.params.isTeacherPlan === '1'
    this.planId = this.$route.params.planId as string
    this.cover = this.$route.params.cover as string

    this.loadData(this.planId)
    this.loadPlan()
  }

  loadPlan () {
    service.get('/mindup/mini/plans/current-plan-info', {}).then(res => {
      if (res.data === null) {

      } else {
        this.currentPlan = res.data
      }
    })
  }

  loadData (planId: string) {
    service.get('/mindup/mini/plans/' + planId, {}).then(res => {
      console.log(res.data)
      this.plan = res.data
      this.loadPlanPractices(this.plan.id + '')
    })
  }

  loadPlanPractices (planId: string) {
    service.get('/mindup/mini/plans/' + planId + '/practices', {}).then(res => {
      console.log(res.data)
      this.planPractices = res.data
    })
  }

  tapManageStudents () {
    this.$router.push({
      name: 'StudentManagement',
      params: {
        planId: this.planId
      }
    })
  }

  onClickLeft () {
    this.$router.go(-1)// 返回上一层
  }

  tapStartPlan () {
    if (this.currentPlan.id !== 0 && this.currentPlan.applyStatus !== ApplyStatus.AUDIT_FAILED) {
      Dialog.alert({
        title: '申请失败',
        message: '为保证您的学习效果，您只能在同一时间进行一项计划。请退出其他计划或者取消申请其他计划后再试一次。'
      })
    } else {
      if (this.plan.isNeedAudit) {
        this.isShowDialog = true
      } else {
        service.post('/mindup/mini/plans/' + this.planId + '/students/apply', {
          mobilePhone: '',
          studentName: ''
        }).then(res => {
          Toast.success('已成功参加该计划')
          this.loadData(this.planId)
        })
      }
    }
  }

  tapConfirm () {
    if (this.name.length > 0 && this.phone.length > 0) {
      service.post('/mindup/mini/plans/' + this.planId + '/students/apply', {
        mobilePhone: this.phone,
        studentName: this.name
      }).then(res => {
        this.plan.applyStatus = res.data.applyStatus
        Toast.success('申请已成功发送')
      })
    } else {
      Toast.fail('请输入姓名和手机号')
    }
  }

  tapCancelPlan () {
    Dialog.confirm({
      title: '取消申请？',
      message: '是否确定取消申请',
      confirmButtonText: '确定'
    })
      .then(() => {
        // on confirm
        service.post('/mindup/mini/plans/' + this.planId + '/cancel-application', {}).then(res => {
          this.plan.applyStatus = undefined
        })
      })
      .catch(() => {
        // on cancel
      })
  }

  tapFinishPlan () {
    Dialog.confirm({
      title: '确定结束该计划吗？',
      message: '',
      confirmButtonText: '确定'
    })
      .then(() => {
        // on confirm
        this.finishRequest()
      })
      .catch(() => {
        // on cancel
      })
  }

  finishRequest () {
    service.post('/mindup/mini/plans/' + this.planId + '/finish', {}).then(res => {
      Notify({
        type: 'success',
        message: '操作成功',
        duration: 2000
      })
      setTimeout(() => {
        this.$router.go(-1)
      }, 2000)
    })
  }

  tapChangePlan () {
    Dialog.confirm({
      title: '切换计划？',
      message: '确认切换到该计划',
      confirmButtonText: '切换计划'
    })
      .then(() => {
        // on confirm
        this.auth.setPlanId(parseInt(this.planId))
        this.$router.go(-1)
      })
      .catch(() => {
        // on cancel
      })
  }

  tapStartPractice () {
    this.$router.push({
      name: 'Practice'
    })
  }

  tapPractice (practice: DatePracticeInfo) {
    this.currentPractice = practice
    this.isInfoShow = true
  }
}
